<template>
  <v-dialog v-model="visible" persistent :max-width="maxWidth">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 px-0 font-weight-regular">
        {{ title }}
      </v-card-text>
      <v-divider class="mb-4" />
      <slot name="tableList">
        <v-data-table
          id="table-custom-pegawai"
          class="mt-3"
          :headers="headers"
          :items="lists"
          hide-default-footer
        />
      </slot>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    maxWidth: { type: Number, required: false, default: 800 }
  },
  data() {
    return {
      visible: false,
      title: "",
      lists: [],
      headers: []
    };
  },
  methods: {
    view(item) {
      this.title = item.title;
      this.lists = item.lists;
      this.headers = item.headers;
      this.visible = true;
    }
  }
};
</script>
